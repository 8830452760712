body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.text-orange {
   color: #f39200;
}

.link-black, .link-black:hover, .link-black:active {
    color: #212529;
    text-decoration: none;
}

.question-box {
    padding: 5px;
    border: 1px #282c34 solid;
}

.question-header {
    font-weight: bold;
    font-size: small;
}

.btn-group-xs > .btn, .btn-xs {
  padding: 1rem 1rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

.icon {
  width: 32px;
  height: 32px;
}

label, .label-text {
    vertical-align: middle;
    font-weight: normal;
    margin-bottom: 0;
}

.react-toggle {
    margin-right: 8px;
    vertical-align: middle;
}

.custom-control-lg .custom-control-label::before,
.custom-control-lg .custom-control-label::after {
    top: 0.1rem !important;
    left: -2.5rem !important;
    width: 2rem !important;
    height: 2rem !important;
}

.custom-control-lg .custom-control-label {
    margin-left: 1rem !important;
    font-size: 1.5rem !important;
    width: 100%;
}